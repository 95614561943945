<template>
  <router-view></router-view>
</template>

<script>
// const fb = require("@/firebaseConfig.js");
export default {
  data() {
    return {
      teacher: null,
    };
  },
  watch: {
    // $route: {
    //   handler() {
    //     // if(this.$store)
    //     this.$swal.fire("25131 " + this.teacher.id);
        
    //   },
    //   deep: true,
    // },
  },
  created() {

    // this.teacher = this.$store.state.userData
    // console.log(this.teacher.id,"this.teacher.id");
    // fb.db.collection("_system_info_news").doc(this.teacher.id).get().then((doc)=>{
    //   console.log(doc,"aaaa")
    //   if(!doc.exists){
    //     fb.db.collection("_system_info_news").doc(this.teacher.id).set({readAt:new Date()})
    //   }
    // })
  },
};
</script>
