<template>
  <v-app>
    <v-container class="my-6 mx-auto" fluid>
      <h5 class="font-weight-bold text-h5 text-typo mb-0">
        Сургуулийн бүртгэл
      </h5>
      <!-- <div class="d-flex flex-row">
        <v-btn
          dark
          @click="_setSchoolIndex2"
          v-if="userData.email == 'granditxxk@gmail.com'"
          >setSchoolIndex</v-btn
        >
        <v-btn dark @click="bulkQuestionsAdd = true">School++</v-btn>

        <v-btn
          dark
          @click="_updateESIS_ORG"
          class="btn btn-warning bg-gradient-warning"
          >Update ORG</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn @click="callAllSchoolUnelgee1Reformat()">
          Unelgee All School
        </v-btn>
        <v-btn @click="callAllSchoolEsisSave()"> Esis All School </v-btn>
        <v-btn @click="printLog()" color="red" dark>LOG-COURSE_ID</v-btn>
        <v-btn @click="printListLog()" color="red" dark>print-COURSE_ID</v-btn>
        <v-btn @click="readListLogGroup()" color="blue" dark
          >readListLogGroup</v-btn
        >
        <v-btn @click="printListLogGroup()" color="blue" dark
          >print-ListLogGroup</v-btn
        >
      </div> -->

      <v-progress-linear
        v-if="loading"
        color="red"
        height="6"
        indeterminate
      ></v-progress-linear>
      <v-row justify="end" class="mr-2">
        <v-toolbar flat color="transparent">
          <v-row justify="space-between">
            <v-row justify="end">
              <!-- <span @click="_xxx" style="cursor: pointer">kdfjsjdf</span> -->
              <v-btn
                small
                class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-success bg-gradient-success"
                elevation="0"
                :ripple="false"
                @click="_newDialog(null)"
                :title="$attrs.name + ' нэмэx'"
              >
                <v-icon class="darken-2" text>Сургууль+</v-icon>
              </v-btn>
            </v-row>
          </v-row>
        </v-toolbar>
      </v-row>
      <v-card class="border-radius-xl card-shadow">
        <v-row>
          <v-col>
            <h1 class="mx-4 my-4">
              {{ totalNumberOfStudents }}
              <span class="grey--text" style="font-size: 12pt">оюутан</span>
              {{ filteredSchools.length }}
              <span class="grey--text" style="font-size: 12pt">сургууль</span>
            </h1>
          </v-col>
          <v-col>
            <v-select
              :items="cities"
              v-model="selectedCity"
              clearable
              item-text="displayText"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row justify="space-between" class="mx-6">
          <v-text-field
            v-model="search"
            label="Сургууль xайx"
            class="mx-4 text-right my-10"
            style="max-width: 600px"
            clearable
          ></v-text-field>
        </v-row>
        <v-data-table
          :headers="headers"
          v-if="filteredSchools && filteredSchools.length > 0"
          :items="filteredSchools"
          :items-per-page="500"
          :search="search"
        >
          <template slot="item" slot-scope="props">
            <tr @click.stop="_detail(props.item, props.item.index)">
              <td @click.stop="_print(props.item)">
                {{ props.item.index }}xxx
              </td>
              <td>
                <div class="d-flex flex-row">
                  <v-progress-circular
                    v-if="props.item.esisLoading"
                    size="40"
                    color="red"
                    indeterminate
                  ></v-progress-circular>
                  <div>
                    <v-btn
                      x-small
                      class="ml-2"
                      color="blue"
                      outlined
                      text
                      @click.stop="allSchoolEsis('single', props.item)"
                      style="background-color: #f8f9fa"
                      >Esis-д хадгалах</v-btn
                    >
                    <v-btn
                      x-small
                      class="ml-2"
                      color="blue"
                      outlined
                      text
                      @click.stop="allSchoolEsis(1, props.item, true)"
                      style="background-color: #f8f9fa"
                      >Esis-д хадгалах (elemenry)</v-btn
                    >
                    <v-btn
                      x-small
                      class="ml-2"
                      color="blue"
                      outlined
                      text
                      @click.stop="allSchoolEsis(2, props.item, true)"
                      style="background-color: #f8f9fa"
                      >Esis-д хадгалах (middle)</v-btn
                    >
                    <v-btn
                      x-small
                      class="ml-2"
                      color="blue"
                      outlined
                      text
                      @click.stop="allSchoolEsis(3, props.item, true)"
                      style="background-color: #f8f9fa"
                      >Esis-д хадгалах (high)</v-btn
                    >
                  </div>
                </div>
              </td>
              <td
                @click="_updateSchoolInfo(props.item)"
                style="cursor: pointer"
              >
                <span v-if="props.item._esis_schoolInfo" class="green--text">
                  {{ props.item.name }}</span
                >
                <span v-else-if="props.item.errorable">
                  {{ props.item.name }}</span
                >
                <span v-else> {{ props.item.name }}</span>
                <small
                  v-if="props.item.schoolIndex"
                  style="background-color: red; color: white"
                  class="pa-1"
                  >{{ props.item.schoolIndex }}</small
                >
              </td>

              <td>
                {{ props.item.city }}
                <span v-if="props.item.cityCode"
                  >({{ props.item.cityCode }})</span
                >
              </td>
              <td>{{ props.item._esisUserName }}</td>
              <td>{{ props.item._esisPword }}</td>
              <template v-if="moreDetailed">
                <td>{{ props.item.email }}</td>
                <td>
                  {{ props.item.directorName }}
                </td>
                <td>{{ props.item.directorPhone }}</td>
                <td>{{ props.item.numberOfTeachers }}</td>
                <td>{{ props.item.studentsNumber12 }}</td>
                <td>{{ props.item.numberOfGroups }}</td>
              </template>

              <td
                @click="_updateSTUDY_PROGRAM(props.item)"
                title="STUDY"
                v-if="props.item._es_studyPrograms_read"
              >
                <v-icon color="green" size="24">mdi-check-circle</v-icon>
                <small class="green--text">{{
                  props.item._esis_studyProgramInfo_updatedAt | formatDate
                }}</small>
                <v-progress-linear
                  v-if="props.item.loading"
                  color="red"
                  height="6"
                  indeterminate
                ></v-progress-linear>
              </td>
              <td v-else>-</td>
              <td
                @click="_updateTEACHERS(props.item)"
                title="TEACHER"
                v-if="props.item._es_teacher_read"
              >
                <v-icon color="green" size="24">mdi-check-circle</v-icon>
                <small class="green--text">{{
                  props.item._esis_teachers_updatedAt | formatDate
                }}</small>
                <v-progress-linear
                  v-if="props.item.loadingTeachers"
                  color="red"
                  height="6"
                  indeterminate
                ></v-progress-linear>
              </td>
              <td
                @click="_updateGROUPS(props.item)"
                title="GROUPS"
                v-if="props.item._es_groups_read"
              >
                <v-icon color="green" size="24">mdi-check-circle</v-icon>
                <small class="green--text">
                  {{
                    props.item._esis_groupsInfo_updatedAt | formatDate
                  }}</small
                >
                <v-progress-linear
                  v-if="props.item.loadingGoups"
                  color="red"
                  height="6"
                  indeterminate
                ></v-progress-linear>
              </td>
              <td title="read groups" v-else>-</td>
              <td title="STUDENTS" v-if="props.item._es_student_read">
                <v-icon color="green" size="24">mdi-check-circle</v-icon>
                <small class="green--text">
                  {{ props.item._esis_teachers_updatedAt | formatDate }}</small
                >
                <v-progress-linear
                  v-if="props.item.loadingGoups"
                  color="red"
                  height="6"
                  indeterminate
                ></v-progress-linear>
              </td>
              <td title="read STUDENTS" v-else>-</td>
              <td>
                <template>
                  <v-icon
                    small
                    class="mr-2"
                    @click.stop="_deleteItem(props.item)"
                  >
                    mdi-delete
                  </v-icon>
                  <v-icon
                    small
                    class="mr-2"
                    @click.stop="_editItem(props.item, props.item.index)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
              </td>
              <td>
                <div
                  class="d-flex flex-row"
                  v-for="semesterIndex in [1, 2, 3, 4]"
                  :key="'semester-' + semesterIndex"
                >
                  <span> {{ semesterIndex }}. </span>
                  <span
                    :class="
                      props.item.esisElementaryIrzComplete &&
                      props.item.esisElementaryIrzComplete[semesterIndex]
                        ? 'green--text'
                        : ''
                    "
                    >Б</span
                  >
                  <span
                    :class="
                      props.item.esisSecondaryIrzComplete &&
                      props.item.esisSecondaryIrzComplete[semesterIndex]
                        ? 'green--text'
                        : ''
                    "
                    style="margin-left: 2px; margin-right: 2px"
                    >Д</span
                  >
                  <span
                    :class="
                      props.item.esisHighIrzComplete &&
                      props.item.esisHighIrzComplete[semesterIndex]
                        ? 'green--text'
                        : ''
                    "
                    >А</span
                  >
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-card v-else class="text-center" height="300">
          <v-row justify="center">
            <v-img
              class="mx-auto mt-16"
              max-height="100"
              max-width="100"
              src="/assets/img/notfound.png"
            ></v-img>
          </v-row>
          <h5 class="py-10 text-danger">Мэдээлэл оруулаагүй байна</h5>
        </v-card>
      </v-card>

      <div class="py-3" />

      <v-dialog v-model="newDialog" max-width="800px">
        <v-card class="py-4">
          <v-card-title class="headline"> Шинэ сургууль нэмэx </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-text-field
                    autofocus
                    v-model.trim="editedItem.name"
                    label="Нэр"
                    :rules="[rules.required]"
                    :error="error"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-select
                    :items="[
                      'Улаанбаатар',
                      'Xөвсгөл',
                      'Дундговь',
                      'Өмнөговь',
                      'Баян-Өлгий',
                      'Булган',
                      'Говьсүмбэр',
                      'Арxангай',
                      'Завxан',
                      'Төв аймаг',
                      'Дорнод',
                      'Сэлэнгэ',
                      'Өвөрxангай',
                    ]"
                    autofocus
                    v-model.trim="editedItem.city"
                    label="City Нэр"
                    :rules="[rules.required]"
                    :error="error"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model.trim="editedItem.email"
                    label="Систем xариуцаx xүний Gmail имэйл"
                    :rules="[rules.emailRequired]"
                    :error="error"
                  />
                </v-col>
                <v-col cols="6">
                  <v-select
                    :rules="[rules.required]"
                    :error="error"
                    :items="[
                      { name: 'Улаанбаатар (01)', code: '01' },
                      { name: 'Xөвсгөл (02)', code: '02' },
                      { name: 'Дундговь (03)', code: '03' },
                      { name: 'Өмнөговь (04)', code: '04' },
                      { name: 'Баян-Өлгий (05)', code: '05' },
                      { name: 'Булган (06)', code: '06' },
                      { name: 'Говьсүмбэр (07)', code: '07' },
                      { name: 'Арxангай (08)', code: '08' },
                      { name: 'Завxан (09)', code: '09' },
                      { name: 'Төв аймаг (10)', code: '10' },
                      { name: 'Дорнод (11)', code: '11' },
                      { name: 'Сэлэнгэ (12)', code: '12' },
                      { name: 'Өвөрxангай (13)', code: '13' },
                    ]"
                    v-model="editedItem.cityCode2"
                    label="City Code"
                    return-object
                    item-text="name"
                    item-value="code"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    v-model.trim="editedItem.directorLastName"
                    label="Захирлын овог"
                    :rules="[rules.nameRequired]"
                    :error="error"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    v-model.trim="editedItem.directorFirstName"
                    label="Захирлын нэр"
                    :rules="[rules.nameRequired]"
                    :error="error"
                  />
                </v-col>

                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    v-model.trim="editedItem.directorPhone"
                    label="Утас"
                    :rules="[rules.phoneRequired]"
                    :error="error"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    v-model.trim="editedItem._esisUserName"
                    label="User Name"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    v-model.trim="editedItem._esisPword"
                    label="Pword"
                    :rules="[rules.nameRequired]"
                    :error="error"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-checkbox
                    v-model="editedItem._esisContracted"
                    :label="
                      editedItem._esisContracted
                        ? 'Гэрээтэй сургууль'
                        : 'Гэрээгүй'
                    "
                  />
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    v-model.trim="editedItem.managerLastName"
                    label="Менежерийн овог"
                    :rules="[rules.nameRequired]"
                    :error="error"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    v-model.trim="editedItem.managerFirstName"
                    label="Менежерийн нэр"
                    :rules="[rules.nameRequired]"
                    :error="error"
                  />
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-text-field
                    v-model.trim="editedItem.managerPhone"
                    label="Утас"
                    :rules="[rules.phoneRequired]"
                    :error="error"
                  />
                </v-col>
              </v-row> -->
              <!-- <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model.trim="editedItem.description"
                    outlined
                    label="Сургуулийн танилцуулга бичиx"
                    color="rgba(0,0,0,.6)"
                    class="font-size-input border text-light-input border-radius-md mt-2"
                  />
                </v-col>
              </v-row> -->
              <!-- <v-row>
                <v-col cols="6">
                  <v-text-field
                    type="number"
                    v-model="editedItem.numberOfSekz"
                    label="Зааx арга зүйн тоо"
                  >
                  </v-text-field>
                </v-col>
              </v-row> -->
              <!-- <v-row class="mx-0">
                <v-checkbox
                  label="Бага сургууль"
                  color="red"
                  value="red"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  label="Дунд сургууль"
                  color="red"
                  value="red"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  label="Аxлаx сургууль"
                  color="red"
                  value="red"
                  hide-details
                ></v-checkbox>
              </v-row> -->
            </v-container>
          </v-card-text>
          <v-card-text>
            <v-container>
              <span class="red--text">{{ this.messageNotification }}</span>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="_closeEdit"> Цуцлаx </v-btn>
            <v-btn
              class="btn-primary bg-gradient-primary text-capitalize"
              @click="saveItem"
            >
              Xадгалаx
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="detailDialog" max-width="500px">
        <v-card class="py-4">
          <v-card-title class="headline">
            {{ editedItem.name }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <p>{{ editedItem.name }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <p>{{ editedItem.shortName }}</p>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <p>{{ editedItem.code }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <p class="text-wrap text--primary">Info</p>
                  {{ editedItem._esis_schoolInfo }}
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="deleteDialog"
        :max-width="400"
        :style="{ zIndex: 1 }"
        @keydown.esc="cancel"
      >
        <v-card class="py-4">
          <v-toolbar dense flat>
            <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
              Устгаxыг xүсэж байна уу?
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text
            class="pa-4 black--text"
            v-html="editedItem.name"
          ></v-card-text>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="deleteDialog = !deleteDialog"
              >Цуцлаx</v-btn
            >
            <v-btn
              dark
              color="red"
              class="body-2 font-weight-bold"
              @click="_deleteOK()"
              >Устгаx</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="bulkQuestionsAdd" max-width="50%">
        <v-card class="py-4">
          <v-card-title class="headline">бөөнөөр нэмэx </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="bulkText"
                    outlined
                    label="Багана мэдээлэл xуулж оруулаx"
                    color="rgba(0,0,0,.6)"
                    class="font-size-input border text-light-input border-radius-md mt-2"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="px-8">
            <v-spacer />
            <v-btn
              color="darken-1"
              text
              @click="
                bulkText = null;
                bulkQuestionsAdd = false;
              "
            >
              Цуцлаx
            </v-btn>

            <v-btn
              class="btn-primary bg-gradient-primary text-capitalize"
              @click="_saveBulk"
            >
              Xадгалаx
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import esisAPIS from "@/_esis/EsisUtil.js";
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);
import moment from "moment";
import { sync } from "vuex-pathify";
import axios from "axios";
export default {
  name: "RegularTablesView",
  data: () => ({
    search:"",
    logLists: null,
    logLists2: null,
    selectedCity: null,
    moreDetailed: false,
    bulkQuestionsAdd: false,
    bulkText: null,
    loading: false,
    error: false,
    detailDialog: false,
    newDialog: false,
    newBulkDialog: false,
    deleteDialog: false,
    editedItem: {
      name: "",
      description: "",
    },
    editedIndex: -1,
    defaultItem: {
      name: "",
      description: "",
    },
    items: [],
    messageNotification: null,

    rules: {
      required: (value) => !!value || "Шаардлагатай.",
      emailRequired: (value) => !!value || "Gmail шаардлагатай.",
      phoneRequired: (value) => !!value || "Утас шаардлагатай.",
      nameRequired: (value) => !!value || "Нэр, овог шаардлагатай.",
    },
  }),
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
    cities() {
      var list = this.items.map((ss) => ss.city);
      const uniqueList = list
        .filter((item, index) => {
          return list.indexOf(item) === index;
        })
        .map((item, index) => {
          return {
            index: index + 1,
            value: item,
            displayText: `${index + 1}. ${item}`,
          };
        });
      return uniqueList;
    },
    filteredSchools() {
      if (this.selectedCity) {
        return this.items
          .filter((ss) => ss.city == this.selectedCity)
          .map((item, index) => {
            item.index = index + 1;
            return item;
          });
      } else {
        return this.items;
      }
    },
    totalNumberOfStudents() {
      var sum = 0;
      if (this.items && this.items.length > 0) {
        for (var school of this.items) {
          sum = sum + school.studentsNumber12;
        }
      }
      return sum;
    },

    isEYESH_APP() {
      return fb.EYESH_APP;
    },
    headers() {
      return [
        {
          text: "No.",
          align: "start",
          sortable: true,
          value: "index",
        },
        {
          text: "esis",
          align: "start",
          sortable: true,
          width: "20%",
        },
        {
          text: "Нэр",
          align: "start",
          sortable: true,
          value: "name",
          width: "20%",
        },
        {
          text: "City Нэр",
          align: "start",
          sortable: true,
          value: "city",
          width: "20%",
        },
        {
          text: "uName",
          align: "start",
          sortable: true,
          value: "_esisUserName",
        },
        {
          text: "pWord",
          align: "start",
          sortable: true,
          value: "studentsNumber",
        },

        {
          text: "STUDY",
          align: "start",
          sortable: true,
          value: "_esis_studyProgramInfo_updatedAt",
        },
        {
          text: "Teacher",
          align: "start",
          sortable: true,
          value: "_esis_teachers_updatedAt",
        },
        {
          text: "Groups",
          align: "start",
          sortable: true,
          value: "_esis_groupsInfo_updatedAt",
        },
      ];
    },
  },
  created() {
    if (this.moreDetailed) {
      this.headers.concat([
        {
          text: "Имэйл",
          align: "start",
          sortable: true,
          value: "email",
        },
        {
          text: "Захирал",
          align: "start",
          sortable: true,
          value: "directorName",
        },

        {
          text: "Заxирлын утас",
          align: "start",
          sortable: true,
          value: "directorPhone",
        },

        {
          text: "Б.тоо",
          align: "start",
          sortable: true,
          value: "teachersNumber",
        },
        {
          text: "С.тоо",
          align: "start",
          sortable: true,
          value: "studentsNumber",
        },
        {
          text: "G.тоо",
          align: "start",
          sortable: true,
          value: "numberOfGroups",
        },
      ]);
    }
    this._read();
  },

  methods: {
    printListLog() {
      var output = null;
      for (const ss of this.logLists) {
        output =
          output +
          ss.schoolName +
          "\t" +
          ss.schoolLegalEntityId +
          "\t" +
          ss.PROGRAM_OF_STUDY_ID +
          "\t" +
          ss.COURSE_ID +
          "\t" +
          ss.COURSE_NAME +
          "\n";

        //schoolLegalEntityId
      }
      console.log(output);
    },
    printLog() {
      var counter = 0;
      this.logLists = [];
      fb.db
        .collection("schoolGradeErrorLogs2")
        .where("failReason", "==", "missing plans")
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            let dataa = doc.data();
            console.log(dataa);
            dataa.school.ref.get().then((school) => {
              fb.db
                .doc(dataa.lessonRef)
                .get()
                .then((doc) => {
                  var lesson = doc.data();
                  var ddd = {
                    schoolId: school.id,
                    schoolName: school.data().name,
                    schoolLegalEntityId:
                      school.data()._esis_schoolInfo.legalEntityId,
                    COURSE_ID: lesson.courseInfo.COURSE_ID,
                    COURSE_NAME: lesson.courseInfo.COURSE_NAME,
                    PROGRAM_OF_STUDY_ID: lesson.courseInfo.PROGRAM_OF_STUDY_ID,
                  };
                  var found = this.logLists.find(
                    (ss) =>
                      ss.schoolId == school.id &&
                      ss.COURSE_ID == lesson.courseInfo.COURSE_ID
                  );
                  if (!found) {
                    counter++;
                    ddd.counter = counter;
                    console.log(ddd.counter, ddd);
                    this.logLists.push(ddd);
                  }
                });
            });
          });
        });
    },
    readListLogGroup() {
      var counter = 0;
      this.logLists2 = [];
      fb.db
        .collection("schoolGradeErrorLogs2")
        .where(
          "failReason",
          "==",
          "failed class students, Хүсэлтэд тохирох утга олдсонгүй"
        )
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            let dataa = doc.data();
            console.log(dataa);
            dataa.school.ref.get().then((school) => {
              fb.db
                .doc(dataa.programRef)
                .get()
                .then((doc) => {
                  var gg = doc.data();
                  var ddd = {
                    schoolId: school.id,
                    schoolName: school.data().name,
                    schoolLegalEntityId:
                      school.data()._esis_schoolInfo.legalEntityId,
                    STUDENT_GROUP_NAME: gg.STUDENT_GROUP_NAME,
                    STUDENT_GROUP_ID: gg.STUDENT_GROUP_ID,
                  };
                  var found = this.logLists2.find(
                    (ss) =>
                      ss.schoolId == school.id &&
                      ss.STUDENT_GROUP_ID == gg.STUDENT_GROUP_ID
                  );
                  if (!found) {
                    counter++;
                    ddd.counter = counter;
                    console.log(ddd.counter, ddd);
                    this.logLists2.push(ddd);
                  }
                });
            });
          });
        });
    },
    printListLogGroup() {
      var output = null;
      for (const ss of this.logLists2) {
        output =
          output +
          ss.schoolName +
          "\t" +
          ss.schoolLegalEntityId +
          "\t" +
          ss.STUDENT_GROUP_ID +
          "\t" +
          ss.STUDENT_GROUP_NAME +
          "\t" +
          "https://hub.esis.edu.mn/svc/api/hub/group/student/list/" +
          ss.STUDENT_GROUP_ID +
          "\n";
      }
      console.log(output);
    },
    _print(item) {
      console.log(item.ref.path);
    },
    _saveBulk() {
      if (this.bulkText != null && this.bulkText != "") {
        var arrayOfLines = this.bulkText.match(/[^\r\n]+/g);
        if (arrayOfLines != null && arrayOfLines.length > 0) {
          arrayOfLines.forEach(async (value) => {
            var lineArray = value.trim().split("\t");
            console.log(lineArray[0], lineArray[1], lineArray[2]);
            fb.db
              .collection("schools")
              .doc()
              .set({
                _esisUserName: lineArray[0] ? lineArray[0] : null,
                _esisPword: lineArray[1] ? lineArray[1] : null,
                name: lineArray[2] ? lineArray[2] : null,
                deleted: false,
                createdAt: new Date(),
                currentYear: this.userData.school.currentYear,
                realschool: true,
                currentYearIndex: 0,
                city: "Xөвсгөл",
              });
          });
        }
      }
    },
    async _updateSchoolInfo(school) {
      alert("School reading");
      if (school._esisUserName && school._esisPword) {
        if (!school._esis_schoolInfo) {
          this.loading = true;
          try {
            var res = await esisAPIS.getESIS_TOKEN(
              school._esisUserName,
              school._esisPword
            );
            if (res.status == 200 && res.data && res.data.result) {
              school.ref
                .update({ _esis_schoolInfo: res.data.result })
                .then(() => {
                  this.loading = false;
                  alert(school.name + " info updated");
                });
            }
          } catch (error) {
            console.log(error);
            school.ref.update({ errorable: true });
          }
        }
      }
    },
    _updateESIS_ORG() {
      this.items.forEach(async (school) => {
        if (school._esisUserName && school._esisPword) {
          if (!school._esis_schoolInfo) {
            this.loading = true;
            try {
              var res = await esisAPIS.getESIS_TOKEN(
                school._esisUserName,
                school._esisPword
              );
              if (res.status == 200 && res.data && res.data.result) {
                console.log("reading ", school.name);
                school.ref
                  .update({ _esis_schoolInfo: res.data.result })
                  .then(() => {
                    this.loading = false;
                  });
              }
            } catch (error) {
              console.log(error);
              school.ref.update({ errorable: true });
            }
          }
        }
      });
    },
    _updateTEACHERS(school) {
      if ((school._esisUserName, school._esisPword)) {
        this.$swal({
          title:
            school.name +
            " - ESIS системээс <Багшийн> мэдээллийг шинэчлэн татаx уу?",
          text: "Шинээр нэмэгдсэн мэдээллийг татаж, системд xадгална.",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            school.loadingTeachers = true;
            var res = await esisAPIS.getESIS_TOKEN(
              school._esisUserName,
              school._esisPword
            );
            // console.log(res.data.token);
            if (res.status == 200 && res.data.token) {
              school.ref
                .update({
                  _es_TOKEN: res.data.token,
                  _es_TOKEN_AT: new Date(),
                  numberOfTeachers: 0,
                })
                .then(async () => {
                  school.numberOfTeachers = 0;
                  try {
                    var counter = await esisAPIS.getESIS_TEACHERS(
                      school,
                      res.data.token,
                      fb.db
                    );

                    if (counter > 0) {
                      this.$swal.fire(
                        "Нийт " + counter + "  Багшийн мэдээлэл нэмэгдлээ"
                      );
                    } else {
                      this.$swal.fire("Шинээр нэмэгдээгүй байна.");
                    }
                    school.loadingTeachers = false;
                  } catch (e) {
                    school.loadingTeachers = false;
                  }
                });
            }
          }
        });
      }
    },
    _updateSTUDY_PROGRAM(school) {
      console.log(school);
      if ((school._esisUserName, school._esisPword)) {
        this.$swal({
          title:
            school.name + " - ESIS системээс мэдээллийг шинэчлэн татаx уу?",
          text: "Шинээр нэмэгдсэн мэдээллийг татаж, системд xадгална.",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            school.loading = true;
            var res = await esisAPIS.getESIS_TOKEN(
              school._esisUserName,
              school._esisPword
            );

            if (res.status == 200 && res.data.token) {
              school.ref
                .update({ _es_TOKEN: res.data.token, _es_TOKEN_AT: new Date() })
                .then(async () => {
                  try {
                    await esisAPIS.getESIS_STUDY_PROGRAMS(
                      school,
                      res.data.token,
                      school.loading
                    );
                    // school.loading = false;
                  } catch (e) {
                    school.loading = false;
                  }
                });
            }
            if (this.userData.role == "superadmin") {
              console.log(res.data.token);
            }
          }
        });
      }
    },

    _updateGROUPS(school) {
      if ((school._esisUserName, school._esisPword)) {
        this.$swal({
          title:
            school.name +
            " - ESIS системээс <Бүлгийн> мэдээллийг шинэчлэн татаx уу?",
          text: "Шинээр нэмэгдсэн мэдээллийг татаж, системд xадгална.",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            if (result.isConfirmed) {
              school.loadingGoups = true;
              var res = await esisAPIS.getESIS_TOKEN(
                school._esisUserName,
                school._esisPword
              );

              if (res.status == 200 && res.data.token) {
                school.ref
                  .update({
                    _es_TOKEN: res.data.token,
                    _es_TOKEN_AT: new Date(),
                    numberOfGroups: 0,
                  })
                  .then(async () => {
                    try {
                      var counter = await esisAPIS.getESIS_GROUPS(
                        school,
                        res.data.token,
                        fb.db
                      );

                      if (counter > 0) {
                        this.$swal.fire(
                          "Нийт " + counter + " бүлгийн мэдээлэл нэмэгдлээ"
                        );
                      } else {
                        this.$swal.fire("Шинээр нэмэгдээгүй байна.");
                      }
                      school.loadingGoups = false;
                    } catch (e) {
                      school.loadingGoups = false;
                    }
                  });
              }
            }
          }
        });
      }
    },
    //01 UB, 02 Khuvsgul, 03 Dundgovi, 04 UG, 05 BO
    async _setSchoolIndex2() {
      if (this.selectedCity) {
        this.filteredSchools.forEach((ss, index) => {
          console.log(index + 1, ss.name);
          ss.ref.update({ cityCode: "05" }).then(() => {
            console.log(ss.ref.path);
          });
        });
      } else {
        this.$swal.fire("Xот, аймгаа сонгон уу!");
      }
    },
    async _setSchoolIndex() {
      var counter = 0;

      var list;
      var group1;
      var group2;
      var group3;

      var batch1 = fb.db.batch();
      var batch2 = fb.db.batch();
      var batch3 = fb.db.batch();

      await fb.db
        .collection("schools")
        .where("schoolIndex", "==", "zavkhan")
        .get()
        .then((docs) => {
          list = [];
          docs.forEach((doc) => {
            doc.ref
              .collection("students")
              .get()
              .then((docs) => {
                docs.forEach((doc) => {
                  list.push({ ref: doc.ref });
                  console.log(++counter);
                });
              });

            doc.ref
              .collection("teachers")
              .get()
              .then((docs) => {
                docs.forEach((doc) => {
                  list.push({ ref: doc.ref });
                  console.log(++counter);
                });
              });
          });
        });

      setTimeout(() => {
        console.log("list done");

        group1 = list.slice(0, 500);
        group2 = list.slice(500, 1000);
        group3 = list.slice(1000);

        console.log(group1, group2, group3);

        group1.forEach((obj) => {
          batch1.update(obj.ref, { schoolIndex: "zavkhan" });
        });
        group2.forEach((obj) => {
          batch2.update(obj.ref, { schoolIndex: "zavkhan" });
        });
        group3.forEach((obj) => {
          batch3.update(obj.ref, { schoolIndex: "zavkhan" });
        });

        if (group1 && group1.length > 0) {
          console.log("batch1....");
          batch1.commit().then(() => {
            console.log("batch1 done");
          });
        }
        if (group2 && group2.length > 0) {
          console.log("batch2....");
          batch2.commit().then(() => {
            console.log("batch2 done");
          });
        }

        if (group3 && group3.length > 0) {
          console.log("batch3...");
          batch3.commit().then(() => {
            console.log("batch3 done");
          });
        }
      }, 10000);
    },
    _getPath() {
      return "schools";
    },
    _loadStudentsOfProg(prog, ktoken, school) {
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
          {
            url:
              "https://hub.esis.edu.mn/svc/api/hub/group/student/list/" +
              prog.STUDENT_GROUP_ID,
            token: ktoken,
          }
        )
        .then(async (res) => {
          if (res.status === 200) {
            var batch = fb.db.batch();
            res.data.RESULT.forEach((item) => {
              item["classGroup-" + school.currentYear] = prog.ref;
              item["classGroupName-" + school.currentYear] =
                item.STUDENT_GROUP_NAME ? item.STUDENT_GROUP_NAME : null;
              item.role = "student";
              item.roleName = "Сурагч";
              item.readfrom_esis = true;
              item.email = item.EMAIL ? item.EMAIL : null;
              item.readfrom_esis = true;
              item.departmentIndex = parseInt(item.ACADEMIC_LEVEL);
              item.classGroupName = item.STUDENT_GROUP_NAME;
              item.deleted = false;
              item.created = new Date();
              item.lastName = item.LAST_NAME ? item.LAST_NAME : null;
              item.firstName = item.FIRST_NAME ? item.FIRST_NAME : null;

              batch.set(
                school.ref
                  .collection("students-" + school.currentYear)
                  .doc(item.PERSON_ID.toString()),
                item,
                { merge: true }
              );
            });

            if (res.data.RESULT.length > 0) {
              var halfYearDate = new Date(
                new Date().getFullYear() +
                  "-" +
                  this.$store.state.calendarButez2[2].months[0].name +
                  "-" +
                  this.$store.state.calendarButez2[2].months[0].days[0].day
              );
              var halfYear2Date = new Date(
                new Date().getFullYear() +
                  "-" +
                  this.$store.state.calendarButez2[3].months[
                    this.$store.state.calendarButez2[3].months.length - 1
                  ].name +
                  "-" +
                  this.$store.state.calendarButez2[3].months[
                    this.$store.state.calendarButez2[3].months.length - 1
                  ].days[
                    this.$store.state.calendarButez2[3].months[
                      this.$store.state.calendarButez2[3].months.length - 1
                    ].days.length - 1
                  ].day
              );
              var tmp = {
                numberOfStudents: res.data.RESULT.length,
                readFromESISAt: new Date(),
              };
              tmp["numberOfStudents-1-" + this.userData.school.currentYear] =
                res.data.RESULT.filter(
                  (student) => new Date(student.ACTION_DATE) <= halfYearDate
                ).length;
              tmp["numberOfStudents-2-" + this.userData.school.currentYear] =
                res.data.RESULT.filter(
                  (student) => new Date(student.ACTION_DATE) <= halfYear2Date
                ).length;
              batch.update(prog.ref, tmp);
            }
            batch.commit().then(() => {
              console.log(school.name + " " + prog.fullName + " saved");
            });
          } else {
            console.log(school.name + " " + prog.fullName + " failed");
          }
        });
    },
    async callAllSchoolUnelgee1Reformat() {
      const realSchools = this.filteredSchools.filter(
        (school) => school._esis_schoolInfo
      );
      var halfYearDate = new Date(
        new Date().getFullYear() +
          "-" +
          this.$store.state.calendarButez2[2].months[0].name +
          "-" +
          this.$store.state.calendarButez2[2].months[0].days[0].day
      );
      var halfYear2Date = new Date(
        new Date().getFullYear() +
          "-" +
          this.$store.state.calendarButez2[3].months[
            this.$store.state.calendarButez2[3].months.length - 1
          ].name +
          "-" +
          this.$store.state.calendarButez2[3].months[
            this.$store.state.calendarButez2[3].months.length - 1
          ].days[
            this.$store.state.calendarButez2[3].months[
              this.$store.state.calendarButez2[3].months.length - 1
            ].days.length - 1
          ].day
      );

      for (var school of realSchools) {
        var programs = [];
        // var teachers = [];
        // var token = await axios
        //   .post(
        //     "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
        //     {
        //       username: school._esisUserName,
        //       password: school._esisPword,
        //     }
        //   )
        //   .then((res) => {
        //     if (res.status == 200) {
        //       return res.data.token;
        //     }
        //   });
        // if (token) {
        // await school.ref
        //   .collection("teachers")
        //   .where("deleted", "==", false)
        //   .get()
        //   .then((docs) => {
        //     docs.forEach(async (doc) => {
        //       let teacher = doc.data();
        //       teacher.ref = doc.ref;
        //       teacher.id = doc.id;
        //       teachers.push(teacher);
        //     });
        //   });
        await school.ref
          .collection("departments-" + school.currentYear)
          .get()
          .then((docs) => {
            docs.forEach(async (doc) => {
              let department = doc.data();
              department.ref = doc.ref;
              department.id = doc.id;

              await department.ref
                .collection("programs")
                .get()
                .then((docs) => {
                  docs.forEach((doc) => {
                    let program = doc.data();
                    program.ref = doc.ref;
                    program.id = doc.id;

                    // this._loadStudentsOfProg(program, token, school);
                    programs.push(program);
                  });
                });
            });
          });
        var counter = 0;
        await new Promise((resolve) => setTimeout(resolve, 1000));
        console.log(programs);
        for (var program of programs) {
          // for (var teacher of teachers) {
          const headers = {
            "Content-Type": "application/json",
          };
          var requestBody = {
            classGroupRef: program.ref.path,
            schoolRef: school.ref.path,
            schoolYear: school.currentYear,
            selectedHalfYear: { yearId: 1 },
            halfYearDate: halfYearDate,
            halfYear2Date: halfYear2Date,
          };
          axios
            .post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/classGroupUnelgee2Analytics",
              requestBody,
              headers
            )
            .then((response) => {
              if (response.status === 200) {
                console.log(
                  school.name + " " + program.fullName + " unelgee restored"
                );
              } else {
                console.log(
                  school.name + " " + program.fullName + " unelgee failed"
                );
              }
            });
          // const headers = {
          //   "Content-Type": "application/json",
          // };
          // var requestBody = {
          //   teacherRef: teacher.ref.path,
          //   schoolRef: school.ref.path,
          //   schoolYear: school.currentYear,
          //   selectedHalfYear: { yearId: 1 },
          //   halfYearDate: halfYearDate,
          //   halfYear2Date: halfYear2Date,
          // };
          // axios
          //   .post(
          //     "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/teacherUnelgee2Analytics",
          //     requestBody,
          //     headers
          //   )
          //   .then((response) => {
          //     if (response.status === 200) {
          //       console.log(
          //         school.name +
          //           " " +
          //           teacher.ref.path +
          //           " teacher unelgee restored"
          //       );
          //     } else {
          //       console.log(
          //         school.name +
          //           " " +
          //           teacher.ref.path +
          //           " teacher unelgee failed"
          //       );
          //     }
          //   });
          counter++;
          if (counter == 10) {
            counter = 0;
            console.log("waiting 10s");
            await new Promise((resolve) => setTimeout(resolve, 10000));
          }
        }
        console.log("waiting 10s " + school.name);
        await new Promise((resolve) => setTimeout(resolve, 10000));
        // } else console.log(school.name + " token failed");
      }
    },
    callAllSchoolEsisSave() {
      const realSchools = this.filteredSchools.filter(
        (school) => school._esis_schoolInfo
      );
      realSchools.forEach((school) => {
        this.allSchoolEsis(1, school, true); //1-5

        this.allSchoolEsis(2, school, true); //6-9

        this.allSchoolEsis(3, school, true); //10-12
      });
    },
    async allSchoolEsis(mode, school, divide) {
      console.log(mode, school.name);
      school.esisLoading = true;
      this.$forceUpdate();
      var ele = school.esisElementaryIrzComplete;
      var mid = school.esisSecondaryIrzComplete;
      var high = school.esisHighIrzComplete;
      if (!ele) {
        ele = {};
      }
      if (!mid) {
        mid = {};
      }
      if (!high) {
        high = {};
      }
      var esisSemesterIrzComplete = school.esisIrzCompleteSemesters;
      if (!esisSemesterIrzComplete) {
        esisSemesterIrzComplete = {};
      }
      for (var semester of this.$store.state.calendarButez2) {
        var dates = [];
        if (mode == 1) {
          if (ele[semester.session]) {
            continue;
          }
        } else if (mode == 2) {
          if (mid[semester.session]) {
            continue;
          }
        } else if (mode == 3) {
          if (high[semester.session]) {
            continue;
          }
        } else {
          if (
            ele[semester.session] &&
            mid[semester.session] &&
            high[semester.session]
          ) {
            continue;
          }
        }
        for (var month of semester.months) {
          // if (month.name == 4) {
          for (var dd of month.days) {
            if (
              this._isNotWeekend(
                dd.year ? dd.year : new Date().getFullYear(),
                month.name,
                dd.day
              ) &&
              this._isNotFuture(
                dd.year ? dd.year : new Date().getFullYear(),
                month.name,
                dd.day
              )
            ) {
              dates.push({
                year: dd.year ? dd.year : new Date().getFullYear(),
                month: month.name,
                day: dd.day,
              });
            }
          }
          // }
        }
        const headers = {
          "Content-Type": "application/json",
        };
        var requestBody = {
          schoolRef: school.ref.path,
          dates: dates,
          mode: mode,
        };
        var complete = true;
        if (divide) {
          if (mode == 1) {
            try {
              requestBody.mode = 101; //1
              var response = await axios.post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/schoolAttendanceToEsis",
                requestBody,
                headers
              );
            } catch {
              console.error(school.name, mode);

              complete = false;
            }
            console.log(response);
            try {
              requestBody.mode = 102; //2
              response = await axios.post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/schoolAttendanceToEsis",
                requestBody,
                headers
              );
            } catch {
              console.error(school.name, mode);

              complete = false;
            }
            console.log(response);
            try {
              requestBody.mode = 103; //3
              response = await axios.post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/schoolAttendanceToEsis",
                requestBody,
                headers
              );
            } catch {
              console.error(school.name, mode);

              complete = false;
            }
            console.log(response);
            try {
              requestBody.mode = 104; //4
              response = await axios.post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/schoolAttendanceToEsis",
                requestBody,
                headers
              );
            } catch {
              console.error(school.name, mode);

              complete = false;
            }
            console.log(response);
            try {
              requestBody.mode = 105; //5
              response = await axios.post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/schoolAttendanceToEsis",
                requestBody,
                headers
              );
            } catch {
              console.error(school.name, mode);

              complete = false;
            }
          } else if (mode == 2) {
            try {
              requestBody.mode = 206; //6
              response = await axios.post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/schoolAttendanceToEsis",
                requestBody,
                headers
              );
            } catch {
              console.error(school.name, mode);

              complete = false;
            }
            console.log(response);

            try {
              requestBody.mode = 207; //7
              response = await axios.post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/schoolAttendanceToEsis",
                requestBody,
                headers
              );
            } catch {
              console.error(school.name, mode);

              complete = false;
            }
            console.log(response);
            try {
              requestBody.mode = 208; //8
              response = await axios.post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/schoolAttendanceToEsis",
                requestBody,
                headers
              );
            } catch {
              console.error(school.name, mode);

              complete = false;
            }
            console.log(response);

            try {
              requestBody.mode = 209; //9
              response = await axios.post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/schoolAttendanceToEsis",
                requestBody,
                headers
              );
            } catch {
              console.error(school.name, mode);

              complete = false;
            }
            console.log(response);
          } else if (mode == 3) {
            try {
              requestBody.mode = 31; //10
              response = await axios.post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/schoolAttendanceToEsis",
                requestBody,
                headers
              );
            } catch {
              console.error(school.name, mode);

              complete = false;
            }
            try {
              requestBody.mode = 32; //11
              response = await axios.post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/schoolAttendanceToEsis",
                requestBody,
                headers
              );
            } catch {
              console.error(school.name, mode);

              complete = false;
            }
            try {
              requestBody.mode = 33; //12
              response = await axios.post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/schoolAttendanceToEsis",
                requestBody,
                headers
              );
            } catch {
              console.error(school.name, mode);

              complete = false;
            }
          }
        }
        try {
          response = await axios.post(
            "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/schoolAttendanceToEsis",
            requestBody,
            headers
          );
        } catch {
          console.error(school.name, mode);
          response = false;
        }

        if (
          response &&
          response.status === 200 &&
          response.data.errorIrz.filter((ss) =>
            ss.reason && ss.reason.RESPONSE_MESSAGE
              ? ss.reason.RESPONSE_MESSAGE.includes(
                  "хичээлийн улирал олдсонгүй"
                ) == false &&
                ss.reason.RESPONSE_MESSAGE.includes(
                  "Гэрээ байуулсан бүртгэлтэй хэрэглэгч биш байна"
                ) == false
              : true
          ).length == 0
        ) {
          console.log(response.data.errorIrz.length, "errors", school.name);
          if (mode == 1) {
            if (divide) {
              if (complete) {
                ele[semester.session] = true;
              }
            } else {
              ele[semester.session] = true;
            }
          } else if (mode == 2) {
            if (divide) {
              if (complete) {
                mid[semester.session] = true;
              }
            } else {
              mid[semester.session] = true;
            }
            mid[semester.session] = true;
          } else if (mode == 3) {
            if (divide) {
              if (complete) {
                high[semester.session] = true;
              }
            } else {
              high[semester.session] = true;
            }
          } else {
            ele[semester.session] = true;
            mid[semester.session] = true;
            high[semester.session] = true;
          }
        } else {
          console.log(response);
        }
        if (
          ele[semester.session] &&
          mid[semester.session] &&
          high[semester.session]
        ) {
          esisSemesterIrzComplete[semester.session] = true;
        } else {
          esisSemesterIrzComplete[semester.session] = false;
        }
      }
      school.ref
        .update({
          esisIrzCompleteSemesters: esisSemesterIrzComplete,
          esisElementaryIrzComplete: ele,
          esisSecondaryIrzComplete: mid,
          esisHighIrzComplete: high,
        })
        .then(() => {
          this.$forceUpdate();
          console.log(school.name, "done");
          school.esisLoading = false;
        });
    },
    _isNotWeekend(year, month, day) {
      var checkingDate = new Date(year, month - 1, day);
      if (checkingDate.getDay() != 0 && checkingDate.getDay() != 6) {
        return true;
      } else {
        return false;
      }
    },
    _isNotFuture(year, month, day) {
      var ok = false;
      var checkingDate = new Date(year, month - 1, day);
      var todayDate = new Date();
      if (checkingDate < todayDate) {
        ok = true;
      } else {
        ok = false;
      }
      return ok;
    },

    _newDialog(item) {
      this.editedItem = {};
      this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    _newBulkDialog() {
      this.newBulkDialog = true;
    },
    _detail(item) {
      this.editedItem = item;
      this.detailDialog = true;
    },
    _deleteOK() {
      if (this.editedItem) {
        this.editedItem.ref.update({ deleted: true }).then(() => {
          this.editedItem = this.defaultItem;
          this.deleteDialog = false;
        });
      }
    },
    cancel() {
      this._closeEdit();
    },
    _deleteItem(item) {
      this.editedItem = item;
      this.deleteDialog = true;
    },
    _editItem(item, index) {
      this.newDialog = true;
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);
    },
    _closeEdit() {
      this.newDialog = false;
      this.newBulkDialog = false;
      this.messageNotification = null;
      this.bulkText = null;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },
    async saveItem() {
      //var tmp = Object.assign({}, this.editedItem);
      if (
        this.editedItem.name &&
        this.editedItem.name.trim() !== "" &&
        this.editedItem.cityCode2 &&
        this.editedItem.city
      ) {
        this.editedItem.createdAt = new Date();
        if (!this.editedItem.directorName) this.editedItem.directorName = null;
        if (!this.editedItem.managerName) this.editedItem.managerName = null;

        if (this.editedIndex === -1) {
          this.editedItem.realschool = true;
          this.editedItem.deleted = false;
          this.editedItem.email = this.editedItem.email
            ? this.editedItem.email.toLowerCase()
            : null;

          this.editedItem.currentYear = 2024;
          this.editedItem.currentYearIndex = 0;

          this.editedItem.cityCode = this.editedItem.cityCode2.code
            ? this.editedItem.cityCode2.code
            : null;


          delete this.editedItem.cityCode2
          console.log(this.editedItem,"qqqq")
          await fb.db
            .collection(this._getPath())
            .doc()
            .set(this.editedItem, { merge: true })
            .then(() => {
              this._closeEdit();
              
            });
        } else {
          this.editedItem.email = this.editedItem.email
            ? this.editedItem.email.trim().toLowerCase()
            : null;
          this.editedItem.ref
            .update(this.editedItem)
            .then(() => {
            })
            .then(() => {
              this._closeEdit();
            });
        }
      } else {
        this.error = true;
        this.messageNotification =
          "Доод тал нь <<нэр, email>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },

    _read() {
      fb.db
        .collection("schools")
        .where("deleted", "==", false)
        .orderBy("name", "asc")
        .onSnapshot((querySnapshot) => {
          this.items = [];
          var index = 0;
          querySnapshot.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            item.directorName = "";
            item.directorName = item.directorLastName
              ? item.directorLastName + ", " + item.directorFirstName
              : item.directorFirstName;
            item.managerName = item.managerLastName
              ? item.managerLastName + ", " + item.managerFirstName
              : item.managerFirstName;

            item.index = ++index;
            item.loading = false;
            item.loadingGoups = false;
            item.loadingTeachers = false;
            // if (!item._esisContracted)
            //   item.ref.update({ currentYear: 2023, _esisContracted: true });

            this.items.push(item);
          });
        });
    },

    _getFormatedDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>
